<template>
  <ZgyxLayout pageName="用户管理" :breadcrumbs="['用户管理']">
    <UserManagementsComponent />
  </ZgyxLayout>
</template>

<script>
import ZgyxLayout from '@src/components/zgyxLayout/ZgyxLayout.vue';
import UserManagementsComponent from './UserManagementsComponent.vue';
export default {
  name: 'userManagements',
  components: {
    ZgyxLayout,
    UserManagementsComponent
  }
};
</script>

<style lang="less" scoped></style>
