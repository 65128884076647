<template>
  <section ref="content">
    <section class="wrap">
      <ZgyxSearchTablePagination
        ref="searchTablePagination"
        :formConfigs="[
          { type: 'region', label: '用户地区', key: ['regionArry', 'regionNo'] },
          { type: 'select', label: '用户类型', key: 'showRegionType', options: showRegionType },
          { type: 'input', label: '用户名', key: 'username' },
          { type: 'input', label: '姓名', key: 'nickName' },
          { type: 'input', label: '申报单位', key: 'orgName' },
          { type: 'select', label: '用户状态', key: 'useStatus', options: useStatusDic }
        ]"
        tableRowKey="accountId"
        :tableColumns="[
          { type: 'input', dataIndex: 'accountId', title: '序号' },
          { type: 'custom', dataIndex: 'regionNames', title: '地区', width: 240 },
          { type: 'input', dataIndex: 'showRegionTypeText', title: '用户类型', width: 80 },
          { type: 'input', dataIndex: 'username', title: '用户名' },
          { type: 'input', dataIndex: 'nickName', title: '姓名' },
          { type: 'input', dataIndex: 'orgName', title: '申报单位' },
          { type: 'input', dataIndex: 'mobile', title: '手机号码' },
          { type: 'select', dataIndex: 'useStatus', title: '用户状态', options: useStatusDic },
          { type: 'custom', dataIndex: 'action', title: '操作', width: 300 }
        ]"
        :requestFunction="requestFunction"
      >
        <template #formExtends>
          <a-button class="btn" type="primary" @click="onClickAddUser"> <PlusOutlined />新增用户 </a-button>
        </template>
        <template #regionNames="{ record }">
          <a-breadcrumb>
            <a-breadcrumb-item v-for="(item, index) in record.regionNames" :key="index">{{ item }}</a-breadcrumb-item>
          </a-breadcrumb>
        </template>
        <template #action="{ record }">
          <template v-if="userinfo.id != record.accountId">
            <a-button class="btn" type="link" @click="onClickEditUser(record)"> 编辑 </a-button>

            <a-button type="link" size="small" @click="onClickChangePassword(record.accountId)">重置密码</a-button>

            <a-popconfirm
              :getContainer="() => $refs.content"
              title="确认要重置？"
              @confirm="handleResetDefaultPassword(record.accountId)"
            >
              <a-button type="link" size="small" danger>一键重置</a-button>
            </a-popconfirm>

            <a-popconfirm
              :getContainer="() => $refs.content"
              v-if="record.useStatus === 1"
              title="确认要启用禁用？"
              @confirm="postCmsSysUserDisable(record.accountId)"
            >
              <a-button type="link" size="small" danger>禁用</a-button>
            </a-popconfirm>
            <a-popconfirm
              :getContainer="() => $refs.content"
              v-if="record.useStatus === 0"
              title="确认要启用？"
              @confirm="postCmsSysUserEnable(record.accountId)"
            >
              <a-button type="link" size="small">启用</a-button>
            </a-popconfirm>
          </template>
        </template>
      </ZgyxSearchTablePagination>
    </section>

    <UserEditModal
      v-if="visibleUserAdd"
      :activeUser="activeUser"
      :modalType="modalType"
      @onOk="onOkEditUserModal"
      @onCancel="onCancelEditUserModal"
    />

    <AccountChangePassword
      v-if="visibleForChangePassword"
      :accountId="accountIdForChangePassword"
      @onOk="onOkChangePasswordModal"
      @onCancel="onCancelChangePasswordModal"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
// apis
import { apiGetCmsSysUserSearch, apiPostAccountChangeStatus, apiPostAccountResetDefaultPassword } from '@src/apis';
// mixins
// utils
// stores
// configs
// components
import { ZgyxSearchTablePagination } from '@src/components';
import UserEditModal from './components/UserEditModal.vue';
import AccountChangePassword from './components/AccountChangePassword.vue'; //重置密码

export default {
  name: 'UserManagementsComponent',
  components: { ZgyxSearchTablePagination, PlusOutlined, UserEditModal, AccountChangePassword },
  data() {
    return {
      useStatusDic: [
        { name: '启用', value: 1 },
        { name: '禁用', value: 0 }
      ],
      activeUser: {}, // 当前激活的用户
      modalType: 'add', // add/edit
      visibleUserAdd: false, //弹框显示变量

      accountIdForChangePassword: '',
      visibleForChangePassword: false //修改密码弹框显示变量
    };
  },
  computed: {
    ...mapGetters(['dictionaries', 'userinfo']),
    requestFunction() {
      return apiGetCmsSysUserSearch;
    },
    userTypeDic() {
      return this.dictionaries.villageType || [];
    },
    accountType() {
      return this.dictionaries.accountType || [];
    },
    regionType() {
      return this.dictionaries.regionType || [];
    },
    showRegionType() {
      return this.dictionaries.showRegionType || [];
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {});
  },
  methods: {
    /**
     * 一键重置
     * @param {*} accountId
     */
    async handleResetDefaultPassword(accountId) {
      try {
        const _this = this;
        if (accountId) {
          const { code } = await apiPostAccountResetDefaultPassword({ accountId });
          if (code === '00000') {
            message.success('重置成功');
            _this.$refs.searchTablePagination.getList();
          } else {
            // message.error('重置失败');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 编辑用户
     * @param {*} user
     */
    onClickEditUser(user) {
      try {
        const _this = this;
        _this.activeUser = user;
        _this.modalType = 'edit';
        _this.visibleUserAdd = true;
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击新增
     */
    onClickAddUser() {
      try {
        const _this = this;
        _this.activeUser = {};
        _this.modalType = 'add';
        _this.visibleUserAdd = true;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 点击禁用用户
     * @param {*} accountId
     */
    async postCmsSysUserDisable(accountId) {
      try {
        const _this = this;
        if (accountId) {
          const { code } = await apiPostAccountChangeStatus({ accountId, useStatus: false });
          if (code === '00000') {
            message.success('禁用成功');
            _this.$refs.searchTablePagination.getList();
          } else {
            // message.error('禁用失败');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击启用
     * @param {*} accountId
     */
    async postCmsSysUserEnable(accountId) {
      try {
        const _this = this;
        if (accountId) {
          const { code } = await apiPostAccountChangeStatus({ accountId, useStatus: true });
          if (code === '00000') {
            message.success('启用成功');
            _this.$refs.searchTablePagination.getList();
          } else {
            // message.error('启用失败');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 新增或编辑确定回调
     */
    onOkEditUserModal() {
      try {
        const _this = this;
        _this.visibleUserAdd = false;
        _this.$refs.searchTablePagination.getList();
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 新增或编辑取消回调
     */
    onCancelEditUserModal() {
      try {
        const _this = this;
        _this.visibleUserAdd = false;
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 修改密码显示
     * @param {*} accountId
     */
    onClickChangePassword(accountId) {
      try {
        const _this = this;
        _this.accountIdForChangePassword = accountId;
        _this.visibleForChangePassword = true;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 修改密码确定回调
     */
    onOkChangePasswordModal() {
      try {
        const _this = this;
        _this.visibleForChangePassword = false;
        _this.$refs.searchTablePagination.getList();
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 修改密码取消回调
     */
    onCancelChangePasswordModal() {
      try {
        const _this = this;
        _this.visibleForChangePassword = false;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import './UserManagementsComponent.less';
</style>
