<template>
  <section ref="content">
    <a-modal
      :getContainer="() => $refs.content"
      :maskClosable="false"
      title="重置密码"
      visible
      :width="600"
      @ok="onOk"
      @cancel="onCancel"
    >
      <a-form
        :model="formAccount"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :rules="rules"
        autocomplete="off"
        ref="formAccount"
      >
        <a-form-item label="新密码" name="password" :required="true">
          <a-input-password v-model:value="formAccount.password" autocomplete="new-password" />
        </a-form-item>

        <a-form-item label="确认密码" name="passwordConfirm" :required="true">
          <a-input-password v-model:value="formAccount.passwordConfirm" autocomplete="new-password" />
        </a-form-item>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import { message } from 'ant-design-vue';
// apis
import { apiPostAccountChangePassword } from '@src/apis';
// utils
import { createJSEncryptPassword } from '../utils';
// stores
// configs
import { rulesForPassword } from '../configs';
export default {
  name: '',
  props: {
    accountId: Number
  },
  data() {
    return {
      formAccount: {
        //表单对象
        password: '',
        passwordConfirm: ''
      },
      rules: rulesForPassword
    };
  },
  mounted() {},
  computed: {},
  methods: {
    onOk() {
      //提交按钮
      try {
        const _this = this;
        this.$refs.formAccount.validate().then(() => {
          if (this.formAccount.password === this.formAccount.passwordConfirm) {
            _this.changePassword();
          } else {
            message.error('两次密码不一致');
            return false;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async changePassword() {
      const _this = this;
      try {
        const { password, passwordConfirm } = _this.formAccount;
        const { code } = await apiPostAccountChangePassword({
          accountId: _this.accountId,
          password: createJSEncryptPassword(password),
          passwordConfirm: createJSEncryptPassword(passwordConfirm)
        });
        if (code === '00000') {
          message.success('修改成功');
          _this.$emit('onOk');
        } else {
          // message.error('修改失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    onCancel() {
      //取消按钮
      const _this = this;
      _this.$emit('onCancel');
    }
  }
};
</script>

<style lang="less" scoped></style>
