<template>
  <section ref="content">
    <a-modal
      :getContainer="() => $refs.content"
      :maskClosable="false"
      :title="modalTitle"
      visible
      :width="1200"
      @ok="onOk"
      @cancel="onCancel"
    >
      <section class="content">
        <a-spin :spinning="spinning" tip="加载中......" size="large">
          <a-form
            name="addForm"
            :model="formState"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 14 }"
            :rules="rules"
            ref="formState"
            autocomplete="off"
          >
            <template v-if="isAddFlag">
              <a-form-item label="用户地区" name="regionNo">
                <BusinessAreaCascader
                  :areaValues="formState.regionNo"
                  @update:areaValues="onUpdateAreaValues"
                  :areakey="['regionArry', 'regionNo']"
                />
              </a-form-item>

              <a-form-item label="人员类型" name="type">
                <a-radio-group v-model:value="formState.type">
                  <a-radio v-for="item in accountType" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>

              <a-form-item v-if="formState.regionNo.length === 5" label="行政区划类型" name="regionType">
                <a-radio-group v-model:value="formState.regionType">
                  <a-radio v-for="item in userTypeDic" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </template>

            <a-form-item label="用户名" name="username">
              <a-input v-model:value="formState.username" />
            </a-form-item>
            <a-form-item label="姓名" name="nickName">
              <a-input v-model:value="formState.nickName" />
            </a-form-item>
            <a-form-item label="手机号码" name="mobile">
              <a-input type="number" v-model:value="formState.mobile" />
            </a-form-item>

            <section v-if="isAddFlag">
              <a-form-item label="密码" name="password">
                <a-input-password v-model:value="formState.password" autocomplete="new-password" />
              </a-form-item>
              <a-form-item label="确认密码" name="passwordConfirm">
                <a-input-password v-model:value="formState.passwordConfirm" autocomplete="new-password" />
              </a-form-item>
            </section>
          </a-form>
        </a-spin>
      </section>
    </a-modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
// apis
import { apiPostCmsSysUserSave, apiGetUserInfoOther, apiPostCmsSysUserUpdate } from '@src/apis';
// mixins
// utils
import { createJSEncryptPassword } from '../utils';
// stores
// configs
import { rules } from '../configs';
import { BusinessAreaCascader } from '@src/components';

export default {
  name: '',
  props: {
    activeUser: Object,
    modalType: {
      type: String,
      default: () => 'add'
    }
  },
  components: { BusinessAreaCascader },
  data() {
    return {
      rules: rules,
      formState: {
        regionNo: []
      },
      modalTitle: '新增用户',
      rolesList: [],
      spinning: false
    };
  },
  mounted() {
    if (this.isAddFlag) {
      this.modalTitle = '新增用户';
    } else {
      this.modalTitle = '编辑用户';
      this.handleGetSystemAccountInfo();
    }
  },
  computed: {
    ...mapGetters(['dictionaries']),
    isAddFlag() {
      //新增flag
      return this.modalType == 'add' ? true : false;
    },
    userTypeDic() {
      return this.dictionaries.villageType || [];
    },
    accountType() {
      // 专家只能选省、市，租户不限制，5级才选行政区域类型
      const _this = this;
      const { regionNo } = _this.formState;
      const len = regionNo.length;
      const code = regionNo[len - 1];
      const accountType = _this.dictionaries.accountType || [];
      if (code && code.length > 6) {
        return accountType.slice(1, 2);
      } else {
        return accountType;
      }
    }
  },
  methods: {
    /*
     *获取详情
     */
    async handleGetSystemAccountInfo() {
      try {
        const _this = this;
        _this.spinning = true;
        const { code, data } = await apiGetUserInfoOther(_this.activeUser.accountId);
        _this.spinning = false;
        if (code === '00000' && data) {
          _this.formState = Object.assign({}, _this.formState, data ?? {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 更新区域的值
     */
    onUpdateAreaValues(value, key) {
      try {
        const _this = this;
        _this.formState.regionNo = value;
      } catch (error) {
        console.error(error);
      }
    },

    onOk() {
      //提交按钮
      try {
        const _this = this;
        _this.$refs.formState.validate().then(() => {
          if (_this.isAddFlag) {
            if (_this.formState.password !== _this.formState.passwordConfirm) {
              message.error('两次密码不一致');
              return false;
            }
            _this.createUser();
          } else {
            _this.updateUser();
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 创建用户
     * @param {*}
     */
    async createUser() {
      const _this = this;
      try {
        const { formState } = _this;
        const postData = { ...formState };
        postData.password = createJSEncryptPassword(postData.password);
        postData.passwordConfirm = createJSEncryptPassword(postData.passwordConfirm);
        if (postData.regionNo && postData.regionNo.length) {
          postData.regionNo = postData.regionNo[postData.regionNo.length - 1];
        }
        const { code } = await apiPostCmsSysUserSave(postData);
        if (code === '00000') {
          message.success('创建成功');
          _this.$emit('onOk');
        } else {
          // message.error('创建失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 更新用户
     * @param {*}
     */
    async updateUser() {
      const _this = this;
      try {
        const { formState } = _this;
        const { accountId, username, nickName, mobile } = formState;
        const { code } = await apiPostCmsSysUserUpdate({
          accountId,
          username,
          nickName,
          mobile
        });
        if (code === '00000') {
          message.success('编辑成功');
          _this.$emit('onOk');
        } else {
          // message.error('编辑失败');
        }
      } catch (error) {
        console.log(error);
      }
    },

    onCancel() {
      //取消按钮
      const _this = this;
      _this.$emit('onCancel');
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
